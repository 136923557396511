import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { observer } from 'mobx-react';
import { useStores, Stores } from '../../stores';
import views from '../../pages/views';
import Navigation from '../navigation/Navigation';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

function Header() {
    const classes = useStyles();
    const stores: Stores = useStores().store;
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Navigation />

                <Toolbar>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => { stores.theme.openDrawer() }}>
                            <MenuIcon />
                        </IconButton>

                    <Typography variant="h6" className={classes.title}>
                        Meetingate
                    </Typography>
                    <Button
                        onClick={() => stores.router.goTo(views.inner)}
                        color="inherit"
                    >
                        {stores.auth.is_authenticated ? "Logout" : "Login"}
                    </Button>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default observer(Header);