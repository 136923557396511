import React, { MouseEvent, useRef, RefObject } from 'react';
import { observer } from 'mobx-react';
import { useStores, Stores } from '../stores';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Map from '../components/map/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExplanationCard from '../components/card/ExplanationCard';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        height: 100,
        width: 100
    },
    scrollDownButtonIcon: {
        color: 'white',
    },
    scrollDownButton: {
        backgroundColor: '#4287f5',
        borderRadius: '100%',
        position: 'absolute',
        bottom: 10,
    },
    oneGridItem: {
        display: 'flex',
    },
    gridContainer: {
    },
    media: {
        width: 200,
        height: 400,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    logoNameSloganWrapper: {
        flexDirection: 'row',
        textAlign: 'center',
    },
    appName: {
        fontSize: 28,
        display: 'inline-block',
        margin: 15,
    },
    slogan: {
        fontSize: 22,
        display: 'inline-block',

    },
    logo: {
        width: 50,
        height: 50,
    },
    secondPage: {
        height: '100vh',
        backgroundColor: '#f2f2f2',
    },
    marginWrapper: {
        margin: 50,
    },
    scrollUpButton: {
        backgroundColor: '#4287f5',
        borderRadius: '100%',
        top: 10,
    }
}));


const scrollToRef = (ref: RefObject<HTMLDivElement>) => window.scrollTo(0, ref.current ? ref.current.offsetTop : 0)

const MainPage: React.FC = () => {
    const myRef = useRef<HTMLDivElement>(null)

    const classes = useStyles();
    const store: Stores = useStores().store;



    const clickButton = () => {
        store.auth.authenticate();
    }
    return (
        <div>
            <Map />
            <Button className={classes.scrollDownButton} onClick={(el: MouseEvent) => { scrollToRef(myRef); }}>
                <FontAwesomeIcon icon={faArrowCircleDown} className={classes.scrollDownButtonIcon} size='5x' />
            </Button>
            <div ref={myRef} className={classes.secondPage}>
                <Button className={classes.scrollUpButton} onClick={(el: MouseEvent) => { window.scrollTo(0, 0) }}>
                    <FontAwesomeIcon icon={faArrowCircleUp} className={classes.scrollDownButtonIcon} size='5x' />
                </Button>
                <div className={classes.logoNameSloganWrapper}>
                    <img className={classes.logo} src={require("../assets/logo.png")} />
                    <Typography variant='h6'>Meetingate</Typography>
                    <Typography variant='h5'>{store.translations.init_texts?.slogan}</Typography>
                </div>
                <div className={classes.marginWrapper}>
                    <Typography variant="h4" align='center'>{store.translations.translations?.how_does_it_work}</Typography>
                </div>
                <Grid container spacing={9} className={classes.gridContainer}>
                    <Grid item sm={3} xs={12}>
                        <ExplanationCard
                            title={store.translations.translations?.find_items}
                            explanation={store.translations.translations?.find_items_explanation}
                            cardNumber={1}
                            image={"find_items" + (store.user.user?.language.name === 'hebrew' ? "_hebrew" : '') + ".png"}
                        />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <ExplanationCard
                            title={store.translations.translations?.check_availability}
                            explanation={store.translations.translations?.check_availability_explanation}
                            cardNumber={2}
                            image={"check_availability" + (store.user.user?.language.name === 'hebrew' ? "_hebrew" : '') + ".png"}
                        />

                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <ExplanationCard
                            title={store.translations.translations?.schedule_a_meeting}
                            explanation={store.translations.translations?.schedule_a_meeting_explanation}
                            cardNumber={3}
                            image={"schedule_a_meeting" + (store.user.user?.language.name === 'hebrew' ? "_hebrew" : '') + ".png"}
                        />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <ExplanationCard
                            title={store.translations.translations?.plan_your_day_outside}
                            explanation={store.translations.translations?.plan_your_day_outside_explanation}
                            cardNumber={4}
                            image={"plan_your_day_outside.png"}
                        />
                    </Grid>
                </Grid>
            </div>

        </div>
    );
}

export default observer(MainPage);