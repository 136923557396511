import { observable, action } from 'mobx';
import { IItem, ICalendarEvent } from 'meetingate-shared';
import { numberWithCommas } from '../services/utils.service';

export interface IItemsStoreMethods {
    setItemsList(apartments: IItem[]): void;
    setLocation(latitude: number, longitude: number): void;
    setCurrentItem(item: IItem): void;
    setAvailabilities(availabilities: ICalendarEvent[]): void;
    setCurrentDayAvailabilities(availabilities: ICalendarEvent[]): void;
    setCurrentDay(date: Date): void;
    setPriceMin(min: number): void;
    setPriceMax(max: number): void;
    setNumOfRoomsMin(min: number): void;
    setNumOfRoomsMax(max: number): void;
    setFloorMinMax(min?: number, max?: number): void;
    setTotalFloorsInBuildingMinMax(min?: number, max?: number): void;
}

export interface IItemsStore extends IItemsStoreMethods {
    items: IItem[];
    latitude: number;
    longitude: number;
    latitude_delta: number;
    longitude_delta: number;
    current_item?: IItem;
    images?: string[];
    current_item_availabilities: ICalendarEvent[];
    current_day?: Date;
    price_min?: string;
    price_max?: string;
    num_of_rooms_min: number;
    num_of_rooms_max: number;
    floor_min: number;
    floor_max: number;
    total_floors_in_building_min: number;
    total_floors_in_building_max: number;
}


export default class ItemsStore {
    @observable items: IItem[] = [];
    @observable latitude: number = 0;
    @observable latitude_delta: number = 0.0922 * 2;
    @observable longitude: number = 0;
    @observable longitude_delta: number = 0.0421 * 2;
    @observable current_item?: IItem;
    @observable images?: string[];
    @observable current_item_availabilities: ICalendarEvent[] = [];
    @observable current_day?: Date;
    @observable price_min?: string;
    @observable price_max?: string;
    @observable num_of_rooms_min: number = 1;
    @observable num_of_rooms_max: number = 4;
    @observable floor_min: number = 0;
    @observable floor_max: number = 4;
    @observable total_floors_in_building_min: number = 4;
    @observable total_floors_in_building_max: number = 6;

    @action
    public setItemsList(items: IItem[]) {
        this.items = items;
    }

    @action
    public setLocation(latitude: number, longitude: number): void {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    @action
    public setCurrentItem(item: IItem) {
        this.current_item = item;
        this.images = item.images;
    }

    @action
    public setAvailabilities(availabilities: ICalendarEvent[]) {
        this.current_item_availabilities = availabilities;
    }

    @action
    public setCurrentDayAvailabilities(availabilities: ICalendarEvent[]) {
        this.current_item_availabilities = availabilities;
    }

    @action
    public setCurrentDay(date: Date) {
        this.current_day = date;
    }
    @action
    public setPriceMin(min: number) {
        this.price_min = numberWithCommas(min);
    }
    @action
    public setPriceMax(max: number) {
        this.price_max = numberWithCommas(max);
    }

    @action
    public setNumOfRoomsMin(min: number) {
        this.num_of_rooms_min = min / 2;
        console.log('this.num_of_rooms_min is: ', this.num_of_rooms_min);
    }

    @action
    public setNumOfRoomsMax(max: number) {
        this.num_of_rooms_max = max / 2;
        console.log('this.num_of_rooms_max is: ', this.num_of_rooms_max);
    }

    @action
    public setFloorMinMax(min?: number, max?: number) {
        console.log('min,max is: ', min, max);
        if (min || min === 0) {
            this.floor_min = min;
        }
        if (max) {
            this.floor_max = max;
        }
    }

    @action
    public setTotalFloorsInBuildingMinMax(min?: number, max?: number) {
        if (min || min === 0) {
            this.total_floors_in_building_min = min;
        }
        if (max) {
            this.total_floors_in_building_max = max;
        }
    }

}