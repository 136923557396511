import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const baseURL = 'https://api.meetingate.net/';
// export const baseURL = 'http://10.0.2.2:10002/';
// export const baseURL = 'https://appartment.adiluz.com/';


export function init() {
    axios.defaults.baseURL = baseURL;
    axios.defaults.withCredentials = true;
    axios.interceptors.response.use(response => {
        return response;
    }, (error) => {
        console.log('error in interceptors is: ', error);
        return {
            success: false,
            err: error,
            message: error.response && error.response.data ? error.response.data.message : error.response && error.response.statusText ? error.response.statusText : "Unexpected error"
        };
    });
}

const Request = async (params: AxiosRequestConfig): Promise<any> => {
    if (params.url && params.url.indexOf(baseURL) === -1) {
        params.url = baseURL + params.url;
    }
    try {
        const res: AxiosResponse = await axios(params);
        // TODO add validation for status codes
        if (res.data) {
            return res.data;
        }
        return res;
    } catch (e) {
        console.log('e in Request is: ', e);
    }
}

export default Request;