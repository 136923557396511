import React from 'react';

//models
import {Route} from 'mobx-router';
import MainPage from './main.page';
import InnerPage from './inner.page';
import PrivacyPolicy from './privacy.policy.page';
//components

const views = {
    main: new Route({
        path:'/',
        component:<MainPage />
    }),
    inner: new Route({
        path:'/inner',
        component:<InnerPage />

    }),
    privacyPolicy:new Route({
        path:'privacy_policy',
        component:<PrivacyPolicy />
    })
//   userProfile: new Route({
//     path: '/profile/:username/:tab',
//     component: <UserProfile/>,
//     onEnter: () => {
//       console.log('entering user profile!');
//     },
//     beforeExit: () => {
//       console.log('exiting user profile!');
//     },
//     onParamsChange: (route, params, store) => {
//       console.log('params changed to', params);
//     }
//   }),
};
export default views;