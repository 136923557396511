import { ICalendarEvent } from "meetingate-shared";
import { toJS } from "mobx";
import moment from "moment";

export function mapEvents(events: ICalendarEvent[]) {
    let toReturn: any = {};
    for (let i = 0; i < events.length; i++) {
        let ev: ICalendarEvent = toJS(events[i]);
        let year: string = moment(ev.time_started).format('YYYY');
        let month: string = moment(ev.time_started).format('MM');
        let day: string = moment(ev.time_started).format('DD');
        let merged: boolean = false;

        if (!toReturn[year]) {
            toReturn[year] = {};
        }
        if (!toReturn[year][month]) {
            toReturn[year][month] = {};
        }
        if (!toReturn[year][month][day]) {
            toReturn[year][month][day] = [];
        } else {
            for (let j = 0; j < toReturn[year][month][day].length; j++) {
                let dayEvent: ICalendarEvent = toJS(toReturn[year][month][day][j]);
                const dayEventStarted: number = new Date(dayEvent.time_started).getTime();
                const dayEventEnded: number = new Date(dayEvent.time_ended).getTime();
                const eventStarted: number = new Date(ev.time_started).getTime();
                const eventEnded: number = new Date(ev.time_ended).getTime();
                if (
                    dayEvent.type === ev.type &&
                    (
                        (dayEventStarted >= eventStarted && dayEventStarted <= eventEnded) ||
                        (dayEventEnded >= eventStarted && dayEventEnded <= eventEnded)
                    )
                ) {
                    const startTime: number = dayEventStarted < eventStarted ? dayEventStarted : eventStarted;
                    const startEnded: number = dayEventEnded > eventEnded ? dayEventEnded : eventEnded;
                    toReturn[year][month][day][j] = {
                        ...toReturn[year][month][day][j],
                        time_started: new Date(startTime),
                        time_ended: new Date(startEnded)
                    };
                    merged = true;
                }
            }
        }
        if (!merged) {
            toReturn[year][month][day].push(ev);
        }
    }
    return toReturn;
}


export function toEventsArray(data: any): ICalendarEvent[] {
    let toReturn: ICalendarEvent[] = [];
    for (const year in data) {
        if (data.hasOwnProperty(year)) {
            const yearObject = data[year];
            for (const month in yearObject) {
                if (yearObject.hasOwnProperty(month)) {
                    const monthObject = yearObject[month];
                    for (const day in monthObject) {
                        if (monthObject.hasOwnProperty(day)) {
                            const dayArray = monthObject[day];
                            for (let dayIndex = 0; dayIndex < dayArray.length; dayIndex++) {
                                toReturn.push(dayArray[dayIndex]);
                            }
                        }
                    }
                }
            }
        }
    }
    return toReturn;
}

export interface IEventType {
    name: string;
    color: string;
}

export const eventTypes: IEventType[] = [
    {
        name: "phone",
        color: 'cyan',

    },
    {
        name: "at_the_location",
        color: 'purple',

    },
    {
        name: "home",
        color: 'green',

    },
    {
        name: "meeting_request-at_the_location",
        color: 'yellow',
    },
];


export function getEventByName(name: string): IEventType {
    return eventTypes.filter((val:IEventType,index:number)=>{
        return val.name === name;
    })[0];
}
