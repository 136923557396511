import React, { Component, FunctionComponent } from 'react';
import GoogleMapReact from 'google-map-react';
import { observer } from 'mobx-react';
import { Stores, useStore } from '../../stores';
import { IItem } from 'meetingate-shared';

interface IAnyComponent {
    text: string;
    lat: number;
    lng: number;
}

const AnyReactComponent = (props: IAnyComponent) => <div>{props.text}</div>;

const Map: FunctionComponent = () => {
    const store: Stores = useStore().store;
    const defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };
    console.log('store.items.latitude is: ', store.items.latitude);
    return (
        // Important! Always set the container height explicitly
        <div style={{ height: '100vh', width: '100%' }}>
            {
                store.user.did_init ?

                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyAVelI0unlQntDvCyRVypfIgZyh0LU5nPk' }}
                        defaultCenter={{
                            lat: store.items.latitude,
                            lng: store.items.longitude,
                        }}
                        defaultZoom={defaultProps.zoom}
                        options={{
                            zoomControl: false,
                            gestureHandling: 'greedy',
                        }}
                    >
                        {
                            store.items.items.map((item: IItem, index: number) => {
                                return (
                                    <AnyReactComponent
                                        lat={item.place.geometry.location.lat}
                                        lng={item.place.geometry.location.lng}
                                        key={index}
                                        text={item.place.name}
                                    />
                                )
                            })
                        }

                    </GoogleMapReact>
                    :
                    <div>Loading</div>
            }
        </div>

    );
}

export default observer(Map);