import { observable, action } from 'mobx';
import { IItem } from 'meetingate-shared';

export interface IMyItemsStoreMethods {
    setItems(items: IItem[]): void;
}

export interface IMyItemsStore extends IMyItemsStoreMethods {
    items: IItem[];
}


export default class MyItemstore {
    @observable items: IItem[] = [];

    @action
    public setItems(items: IItem[]) {
        this.items = items;
    }

}