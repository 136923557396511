import React, { FunctionComponent } from 'react';
import './App.css';
import { MobxRouter } from 'mobx-router';
import { observer } from 'mobx-react';
// import { useStores } from './stores/index';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import Header from './components/header/Header';
import Request, { init } from "./services/axios.service";
import axios from "axios";
import { ILoginRequest, ILoginResponse, IItem } from 'meetingate-shared';
import { useStore, Stores } from './stores';
import { setMomentLocale } from './services/moment.service';




function randomId(): string {
    const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
    return uint32.toString(16);
}

const App: FunctionComponent = () => {
    const store: Stores = useStore().store;
    const initialProcess = async () => {
        init();
        const ipData = await axios.get('https://ipapi.co/json/', { withCredentials: false });
        store.items.setLocation(ipData.data.latitude, ipData.data.longitude);
        store.translations.setLanguageCode(ipData.data.languages.split(',')[0]);
        store.translations.setCountryCode(ipData.data.country_code);
        store.translations.setUuid(randomId());
        store.translations.setCurrency(ipData.data.currency);
        if (store.translations.language_code) {
            setMomentLocale(store.translations.language_code);
        }
        if (store.translations.uuid && store.translations.language_code && store.translations.currency) {
            const loginRequestData: ILoginRequest = {
                device: {
                    uuid: store.translations.uuid,
                    browser: true,
                },
                location: {
                    latitude: ipData.data.latitude,
                    longitude: ipData.data.longitude,
                    latitudeDelta: store.items.latitude_delta,
                    longitudeDelta: store.items.latitude_delta
                },
                language_code: store.translations.language_code,
                currency: store.translations.currency
            };
            const loginResponse: ILoginResponse = await Request({
                method: "POST",
                url: 'auth/login/device',
                data: loginRequestData
            });
            store.translations.setTranslations(loginResponse.texts);
            store.items.setItemsList(loginResponse.items);
            store.my_items.setItems(loginResponse.my_items as IItem[]);
            store.user.setUser(loginResponse.user);
            store.user.setNotifications(loginResponse.notifications);
            store.user.setEvents(loginResponse.events);
            // store.translations.setLoadingStatus('loading_media');

            setTimeout(() => {
                // store.translations.setLoadingStatus('finalizing');
                store.user.setInit();
            }, 1000)

        }
    }
    const theme: ThemeOptions = createMuiTheme({
        palette: {
            primary: purple,
            secondary: green,
        },
        typography: {
            fontFamily: [
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        direction: store.user.language_direction,

    });
    initialProcess();
    // const store = useStores();
    // console.log('stores is: ',store.store.router);
    return (
        <ThemeProvider theme={theme}>
            <div style={{ direction: store.user.language_direction }}>
                <Header />
                <MobxRouter />
            </div>
        </ThemeProvider>
    );
}

export default observer(App);
